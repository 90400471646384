import React, { useState } from 'react'
import './App.css';
import { PaginatedList } from 'react-paginated-list';


let fetchPosts = (getPosts, setPosts,setUsers, setTopics, setTopic) => {
  return fetch("/wp_posts2.json")
    .then(response => response.json())
    .then(response => response.filter(post => post.post_content !== ""))
    .then(response => response.sort((a, b) => parseInt(a.ID) - parseInt(b.ID)))
    .then(response => {
      setPosts(response)
      return response
    })
    .then((posts) => {
      return fetch("/wp_comments.json")
        .then(response => response.json())
        .then(response => response.map(comment => {
          return {
            post_parent: comment.comment_post_ID,
            comment_author: comment.comment_author,
            post_date: comment.comment_date,
            post_content: comment.comment_content,
            post_title: "Reply To blog post",
            comment: true
          }
        }))
        .then(response => {
          let finalPosts = posts.concat(response)
          setPosts(finalPosts)
          return finalPosts
        })})
    .then((posts) => {
      let topics = [...new Set(posts.filter(post => post.post_type !== 'revision').map(post => [post.ID, post.post_title]).filter(topic => !topic[1].includes("Reply To")))]
      setTopics(topics)
      let urlParams = new URLSearchParams(window.location.search);
      let urlTopic = urlParams.get("topic");
      if (urlTopic && urlTopic.length > 0 && topics.length > 0) {
        let found_topic = topics.find(topic => topic[0] === urlTopic);
        setTopic(found_topic ? found_topic : []);
      }
    })
    .then(() => fetch("/wp_users.json"))
    .then(response => response.json())
    .then(response => setUsers(response))
}

const Viesti = ({ post, user }) => {
  const author = user ? user.display_name : post.comment_author;
  let classes = "viesti " + post.post_type
  return (
    <div className={classes}>
      <h2>{post.post_title} <i className="detail">[{post.post_type}]</i></h2> 
      <h3 className="author">{author}</h3> <i>{post.post_date}</i> 
      <p dangerouslySetInnerHTML={{ __html: post.post_content }}></p>
      <hr />
    </div>
  )
}

const Valitsija = ({topics, topic, setTopic, updateUrl, setPage}) => {
  let selected = topic.length > 0 ? topic[0] : 0;
  const updateTopic = (event) => {
    const selected_topic = topics.find(topic => topic[0] === ""+event.target.value)
    setPage(1)
    setTopic(selected_topic ? selected_topic : [])
    updateUrl(1,event.target.value)
  }
  return (
    <select value={selected} onChange={updateTopic}>
      <option key="kaikki" value={[]}>Kaikki</option>
      {topics.map(topic => (
        <option key={topic[0]} value={topic[0]}>{topic[1]}</option>
      ))}
    </select>
  )

}

function App() {
  const [ready, setReady] = useState(false);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [topic, setTopic] = useState([])
  const [topics, setTopics] = useState([])

  let urlParams = new URLSearchParams(window.location.search);
  if (!ready) {
    fetchPosts(posts, setPosts, setUsers, setTopics, setTopic);
    setPage(parseInt(urlParams.get("sivu")));
    setReady(true)
  }

  let updateUrl = (sivu, uusi_topic) => { 
    let urlParams = new URLSearchParams();
    urlParams.set("sivu", sivu);
    if (uusi_topic) urlParams.set("topic", uusi_topic);
    window.history.pushState("", "", "/?"+urlParams.toString());
  }

  return (
    <div className="App">
      <h1>Klaani 3</h1>
      <Valitsija topics={topics} topic={topic} setTopic={setTopic} setPage={setPage} updateUrl={updateUrl} />
      <PaginatedList
        list={topic.length === 0 ? posts : posts.filter(post => post.post_title === topic[1] || post.post_parent === topic[0])}
        itemsPerPage={50}
        currentPage={page ? page : 1}
        onPageChange={(items, currentPage) => updateUrl(currentPage,topic[0])}
        renderList={list => (
          list.map((post, id) => (
            <Viesti key={topic[0]+":"+id} post={post} user={users.find(user => user.ID === post.post_author)} />
          ))
        )}
      />
    </div>
  );
}

export default App;
